import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

export default function SchoolMenu({ onMenuChange }) {
  const handleMenuChange = () => {
    onMenuChange(null);
  };

  return (


    <Nav className="ms-auto navbar-height">
      <Nav className="me-auto mb-2 mb-lg-0">
      </Nav>
      <Nav.Item>
        <Nav.Link as={NavLink} exact to="/" activeClassName="active" onClick={handleMenuChange}>
          Inicio
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/classes" activeClassName="active">
          Clases
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/events" activeClassName="active">
          Eventos
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/team" activeClassName="active">
          Equipo
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/gallery" activeClassName="active">
          Galería
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/contact" activeClassName="active">
          Contacto
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/about" activeClassName="active">
          Sobre Nosotros
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}
