import React from 'react';

function Home() {
  return (
    <div className="home-container">
      <div className="home-content">
        <h1>Bienvenido a la página de inicio</h1>
        <p>Gracias por visitar nuestro <strong>sitio web</strong>.</p>
        <p><strong>Libera tu creatividad!</strong>.</p>
        <p>Descubre la belleza del arte a través de nuestros <strong>productos</strong></p>
        <p>Únete a nuestra <strong>comunidad</strong> de artistas apasionados y deja que tu <strong>imaginación vuele</strong>.</p>

      </div>
    </div>
  );
}

export default Home;
