import React, { useState } from 'react';
import SimpleChatbot from 'react-simple-chatbot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

const Chatbot = ({ onMinimize, handleUserInput }) => {
  const [messages, setMessages] = useState([]);

  const config = {
    steps: [
      {
        id: '1',
        message: 'Welcome to MarovaStudio! How can I assist you?',
        trigger: '2',
      },
      {
        id: '2',
        options: [
          { value: 1, label: 'What classes do you offer?', trigger: '3' },
          { value: 2, label: 'Tell me more about your team.', trigger: '4' },
          { value: 3, label: 'Exit', trigger: 'end' },
        ],
      },
      {
        id: '3',
        message:
          'We offer classes in lettering, design, and more. Check out our Classes page for more information.',
        trigger: '2',
      },
      {
        id: '4',
        message:
          'Our team consists of highly skilled professionals passionate about lettering and design. Learn more on our Team page.',
        trigger: '2',
      },
      {
        id: 'end',
        message: 'Thank you for using MarovaStudio Chatbot. Have a great day!',
        end: true,
      },
    ],
  };

  const handleUserInputFieldChange = (event) => {
    const newMessage = event.target.value;
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    console.log(inputField);
  };

  const inputField = (
    <input
      type="text"
      placeholder="Type your message here..."
      value={messages[messages.length - 1] || ''}
      onChange={handleUserInputFieldChange}
    />
  );

  return (
    <div className="chatbot">
      <div className="chatbot-header">
        <button className="minimize-button" onClick={onMinimize}>
          <FontAwesomeIcon icon={faMinus} />
        </button>
      </div>
      <div className="chatbot-content">
        <SimpleChatbot
          steps={config.steps}
          userSteps={messages}
          handleEnd={() => {}}
          handleUserInput={handleUserInput}
          inputField={inputField}
        />
      </div>
    </div>
  );
};

export default Chatbot;

