import React from 'react';


function Events() {
  return (
  <div>
    <h1>Próximos eventos</h1>
    <div class="evento">
      <h2 class="event-title">Exposición de arte: Expresiones modernas</h2>
      <p class="event-info">Fecha: 25 de junio de 2023</p>
      <p class="event-info">Hora: 6:00 p. m. - 8:00 p. m.</p>
      <p class="event-info">Lugar: Sala de la galería</p>
      <p class="event-description">Únase a nosotros para una noche de cautivadoras obras de arte que muestran expresiones modernas. Experimente la creatividad y el talento de nuestros artistas.</p>
    </div>
    <div class="evento">
      <h2 class="event-title">Taller: Técnicas de medios mixtos</h2>
      <p class="event-info">Fecha: 10 de julio de 2023</p>
      <p class="event-info">Hora: 10:00 a. m. - 12:00 p. m.</p>
      <p class="event-info">Lugar: Estudio B</p>
      <p class="event-description">Aprenda las fascinantes técnicas de combinar diferentes medios artísticos para crear obras de arte únicas y visualmente impactantes. Apto para artistas de todos los niveles.</p>
    </div>
    <div class="evento">
      <h2 class="event-title">Clase de dibujo de figura</h2>
      <p class="event-info">Fecha: 22 de julio de 2023</p>
      <p class="event-info">Hora: 2:00 p. m. - 4:00 p. m.</p>
      <p class="event-info">Lugar: Estudio A</p>
      <p class="event-description">Perfeccione sus habilidades de dibujo y capture la forma humana con la guía de nuestros instructores experimentados. Traiga sus cuadernos de bocetos y lápices.</p>
    </div>
  </div>

  );
}

export default Events;
