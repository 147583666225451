import React from 'react';
import ContactLink from '../ContactLink';

function Contact() {
  return (
    <div>
      <h1>Contactanos</h1>
      <div className="about-content">
        <div class="contact-info">
          <h2>Información de contacto</h2>
          <p><strong>Teléfono:</strong> (849) 620-9141</p>
          <p><strong>Correo electrónico:</strong> design@marovastudio.com</p>
          <p><strong>Visítanos en nuestra ubicación:</strong> Santo Domingo</p>
        </div>

        <div class="connect-social">
          <h2>Conéctate con nosotros</h2>
          <ContactLink />
        </div>
        </div>
        <p>Esperamos saber de usted y responder a cualquier pregunta que pueda tener. Nuestro equipo responderá a sus consultas lo antes posible.</p>
    </div>
  );
}

export default Contact;
