import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

export default function SantoTipoMenu({ onMenuChange }) {
  const handleMenuChange = () => {
    onMenuChange(null);
  };

  return (
    <Nav className="ms-auto navbar-height">
      <Nav.Item>
        <Nav.Link as={NavLink} exact to="/" activeClassName="active" onClick={handleMenuChange}>
          Inicio
        </Nav.Link>
      </Nav.Item>
      {/* Add the specific navigation items for the SantoTipo page */}
      <Nav.Item>
        <Nav.Link as={NavLink} to="/santo-tipo/item1" activeClassName="active">
          Eventos
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/santo-tipo/item2" activeClassName="active">
          Patrocinadores
        </Nav.Link>
      </Nav.Item>
      {/* ... add more items as needed */}
    </Nav>
  );
}
