import React from 'react';

function Gallery() {
  return (
    <div className="gallery-container">
      <h1>Galería de Arte</h1>
      <div className="gallery-content">
        <h2>Explora la belleza del arte</h2>
        <p>Sumérgete en el <strong>captivador mundo del arte</strong> a través de nuestra galería.</p>
        <p>Atestigua la <strong>creatividad y el talento</strong> de nuestros artistas exhibidos en <strong>obras de arte impresionantes</strong>.</p>
        <p>Cada pieza cuenta una <strong>historia única</strong> e invita a emprender un <strong>viaje visual</strong>.</p>

      </div>
      {/* Render your artwork components or images here */}
      <div className="artwork">
        <img src="/images/fancy.avif" alt="Artwork 1" />
        <div className="artwork-details">
          <h3>La baina</h3>
          <p>Un artista</p>
        </div>
      </div>
      <div className="artwork">
        <img src="/images/Dissolve+Framed+On+Canvas+Painting.webp" alt="Artwork 2" />
        <div className="artwork-details">
          <h3>La tipa</h3>
          <p>Artista 2</p>
        </div>
      </div>
      {/* Add more artwork components or images as needed */}
    </div>
  );
}

export default Gallery;
