import React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import SchoolMenu from '../navigation/SchoolMenu';
import ContactLink from '../ContactLink';
import FreelanceMenu from '../navigation/FreelanceMenu';
import SantoTipoMenu from '../navigation/SantoTipoMenu';

export default function MyNavbar({ currentMenu, onMenuChange }) {
  const location = useLocation();
  const navigate = useNavigate();

  // Handle menu selection event
  const handleMenuSelect = (menu) => {
    onMenuChange(menu);
    const paths = {
      school: '/school',
      freelance: '/freelance',
      'santo-tipo': '/santo-tipo',
    };

    const targetPath = paths[menu];
    if (targetPath && location.pathname !== targetPath) {
      navigate(targetPath);
    }
  };

  // Determine whether to show the contact link based on the current path
  const shouldShowContactLink = location.pathname !== '/';

  return (
    <Navbar expand="lg" bg="light" variant="light" className="navbar-height">
      <Container fluid>
        {/* Navbar toggle button */}
        <Navbar.Toggle aria-controls="navbarRightAlignExample" />
        <Navbar.Collapse id="navbarRightAlignExample">
          {/* Left-aligned navigation */}
          <Nav className="me-auto mb-2 mb-lg-0">
            {/* Show contact link if the current path is not root */}
            {shouldShowContactLink && <ContactLink currentMenu={currentMenu} />}
          </Nav>
          {/* Right-aligned navigation */}
          <Nav className="ms-auto">
            {/* Only show the main menu if no specific menu is selected */}
            {currentMenu !== 'school' && currentMenu !== 'freelance' && currentMenu !== 'santo-tipo' && (
              <>
                {/* Home link */}
                <Nav.Item>
                  <Nav.Link as={NavLink} exact to="/" activeClassName="active">
                    Inicio
                  </Nav.Link>
                </Nav.Item>
                {/* Dropdown menu for selecting different menus */}
                <NavDropdown title="Productos" id="products-dropdown">
                  {/* Menu options */}
                  <NavDropdown.Item onClick={() => handleMenuSelect('freelance')}>
                    Freelance
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleMenuSelect('school')}>
                    Escuela
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleMenuSelect('santo-tipo')}>
                    Santo Tipo
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </Nav>
          {/* Render the corresponding menu components based on the current menu */}
          {/* School menu */}
          {currentMenu === 'school' && (
            <Navbar.Collapse id="navbarRightAlignExample" className="ms-auto">
              <SchoolMenu onMenuChange={onMenuChange} />
            </Navbar.Collapse>
          )}
          {/* Freelance menu */}
          {currentMenu === 'freelance' && (
            <Navbar.Collapse id="navbarRightAlignExample" className="ms-auto">
              <FreelanceMenu onMenuChange={onMenuChange} />
            </Navbar.Collapse>
          )}
          {/* Santo Tipo menu */}
          {currentMenu === 'santo-tipo' && (
            <Navbar.Collapse id="navbarRightAlignExample" className="ms-auto">
              <SantoTipoMenu onMenuChange={onMenuChange} />
            </Navbar.Collapse>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
