import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const ContactLink = ({ currentMenu }) => {
  // Define contact link URLs based on the currentMenu value
  const getContactLinkProps = () => {
    switch (currentMenu) {
      case 'school':
        return {
          whatsappUrl: 'https://wa.me/19403129689',
          facebookUrl: 'https://www.facebook.com/hendry.gomez.1',
          twitterUrl: 'https://twitter.com/MarovaMarovita',
          instagramUrl: 'https://www.instagram.com/marovita/',
          youtubeUrl: 'https://www.youtube.com/shorts/D8spNU2pJPc'
        };
      case 'freelance':
        return {
          whatsappUrl: 'https://wa.me/18496209141',
          facebookUrl: 'https://www.facebook.com/marova.oirelav',
          twitterUrl: 'https://twitter.com/MarovaMarovita',
          instagramUrl: 'https://www.instagram.com/marovita/',
          youtubeUrl: 'https://www.youtube.com/shorts/D8spNU2pJPc'
        };
      case 'santo-tipo':
        return {
          whatsappUrl: '/santo-tipo/whatsapp',
          facebookUrl: '/santo-tipo/facebook',
          twitterUrl: '/santo-tipo/twitter',
          instagramUrl: '/santo-tipo/instagram',
          youtubeUrl: '/santo-tipo/youtube'
        };
      default:
        // Use default URLs if no specific menu is selected
        return {
          whatsappUrl: '/default/whatsapp',
          facebookUrl: '/default/facebook',
          twitterUrl: '/default/twitter',
          instagramUrl: '/default/instagram',
          youtubeUrl: '/default/youtube'
        };
    }
  };

  // Get the contact link URLs based on the currentMenu value
  const {
    whatsappUrl,
    facebookUrl,
    twitterUrl,
    instagramUrl,
    youtubeUrl
  } = getContactLinkProps();

  // Handle click event on contact links
  const handleContactLinkClick = (url) => {
    window.open(url);
  };

  return (
    <div className="contact-links">
      <span className="contact-link" onClick={() => handleContactLinkClick(whatsappUrl)}>
        <FontAwesomeIcon icon={faWhatsapp} color="#25D366" />
      </span>
      <span className="contact-link" onClick={() => handleContactLinkClick(facebookUrl)}>
        <FontAwesomeIcon icon={faFacebook} color="#3b5998" />
      </span>
      <span className="contact-link" onClick={() => handleContactLinkClick(twitterUrl)}>
        <FontAwesomeIcon icon={faTwitter} color="#1DA1F2" />
      </span>
      <span className="contact-link" onClick={() => handleContactLinkClick(instagramUrl)}>
        <FontAwesomeIcon icon={faInstagram} color="#C13584" />
      </span>
      <span className="contact-link" onClick={() => handleContactLinkClick(youtubeUrl)}>
        <FontAwesomeIcon icon={faYoutube} color="#FF0000" />
      </span>
    </div>
  );
};

export default ContactLink;
