import React from 'react';

function About() {
  return (
    <div className="about-container">
      <h1>Sobre Nosotros</h1>
      <div className="about-content">
        <p>¡Bienvenido/a a nuestra escuela de arte!</p>
        <p>En nuestra escuela, ofrecemos una amplia gama de clases de arte para estudiantes de todas las edades y niveles de habilidad. Ya sea que seas un principiante buscando explorar tu creatividad o un artista experimentado que desea perfeccionar tu técnica, tenemos algo para todos.</p>
        <p>Nuestro equipo de talentosos e apasionados instructores se dedica a proporcionar una educación artística de alta calidad. Ellos son artistas experimentados y llevan su experiencia al aula, ofreciendo orientación e inspiración a nuestros estudiantes.</p>
        <p>Además de las clases de arte, también organizamos exhibiciones regulares en nuestra galería para mostrar el trabajo de nuestros estudiantes y profesores. Estos eventos brindan una plataforma para que nuestros artistas exhiban sus creaciones y se relacionen con la comunidad artística local.</p>
        <p>En nuestra escuela de arte, creemos en fomentar un ambiente de apoyo y enriquecedor donde la creatividad pueda florecer. Alentamos a nuestros estudiantes a explorar diferentes medios, experimentar con técnicas y descubrir su voz artística única.</p>
        <p>Ya sea que estés buscando seguir el arte como pasatiempo o aspirar a una carrera profesional en las artes, estamos aquí para ayudarte en tu viaje artístico. ¡Únete a nosotros en nuestra escuela de arte y libera tu creatividad!</p>
      </div>
    </div>
  );
}

export default About;
