import React from 'react';

function Classes() {
  const classes = [
    {
      "title": "Taller de pintura al óleo",
      "instructor": "John Smith",
      "date": "5 de julio de 2023",
      "time": "10:00 AM - 1:00 PM",
    },
    {
      "title": "Técnicas de arte abstracto",
      "instructor": "Jane Doe",
      "date": "12 de julio de 2023",
      "time": "2:00 PM - 5:00 PM",
    },
    {
      "title": "Paisajes en acuarela",
      "instructor": "David Johnson",
      "date": "19 de julio de 2023",
      "time": "9:00 AM - 12:00 PM",
    }
    
  ];

  return (
    <div>
      <h1>Clases</h1>
      {classes.map((c, index) => (
        <div className="class" key={index}>
          <h2 className="class-title">{c.title}</h2>
          <p className="class-info">Instructor: {c.instructor}</p>
          <p className="class-info">Fecha: {c.date}</p>
          <p className="class-info">Hora: {c.time}</p>
          <p className="class-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquet, arcu eget tempus dictum, massa tortor tincidunt erat, eu varius risus lectus eu justo.</p>
          <div className="class-action">
            <button className="enroll-button">Inscríbete ahora</button>
            <button className="learn-more-button">Más información</button>
          </div>
        </div>
      ))}
    </div>

  );
}

export default Classes;
