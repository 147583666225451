import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/navigation/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Classes from './components/pages/Classes';
import Team from './components/pages/Team';
import Events from './components/pages/Events';
import Gallery from './components/pages/Gallery';
import SchoolHome from './components/pages/SchoolHome';
import SantoTipoHome from './components/pages/SantoTipoHome';
import FreelanceHome from './components/pages/FreelanceHome';
import Chatbot from './components/Chatbot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faMinus } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [currentMenu, setCurrentMenu] = useState(null);
  const [showChatbot, setShowChatbot] = useState(false);
  const [dialogMinimized, setDialogMinimized] = useState(false);
  const [userInput, setUserInput] = useState('');

  const handleMenuChange = (menu) => {
    setCurrentMenu(menu);
  };

  const toggleChatbot = () => {
    setShowChatbot(!showChatbot);
    if (!showChatbot) {
      setUserInput('');
    }
  };

  const toggleDialogMinimized = () => {
    setDialogMinimized(!dialogMinimized);
  };

  return (
    <Router>
      <div className="App">
        <Navbar currentMenu={currentMenu} onMenuChange={handleMenuChange} />

        <header className="App-header">
          <img src={'/MarovaStudio-1.png'} className="App-logo" alt="logo" />
          <p>
            Bienvenido a <code>MarovaStudio</code>
          </p>
          <p>Escuela de Lettering & Design</p>
          {!showChatbot && !dialogMinimized && (
            <button className="message-icon" onClick={toggleChatbot}>
              <FontAwesomeIcon icon={faCommentAlt} />
            </button>
          )}
        </header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/school" element={<SchoolHome />} />
          <Route path="/freelance" element={<FreelanceHome />} />
          <Route path="/santo-tipo" element={<SantoTipoHome />} />
          <Route path="/classes" element={<Classes />} />
          <Route path="/team" element={<Team />} />
          <Route path="/events" element={<Events />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>

        {showChatbot && !dialogMinimized && (
          <div className="chatbot-container">
            <Chatbot
              userInput={userInput}
              onMinimize={toggleDialogMinimized}
              handleUserInput={(message) => setUserInput(message)}
            />
          </div>
        )}

        {dialogMinimized && (
          <button className="minimize-button" onClick={toggleDialogMinimized}>
            <FontAwesomeIcon icon={faMinus} />
          </button>
        )}
      </div>
    </Router>
  );
}

export default App;
