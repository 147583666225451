import React from 'react';

const instructors = [
    {
      name: 'John Doe',
      position: 'Director de Instrucción',
      bio: 'John Doe es un artista de renombre con más de 10 años de experiencia. Se especializa en pintura abstracta y le encanta experimentar con diferentes técnicas y materiales.',
      image: process.env.PUBLIC_URL + '/images/licensed-image.jpg',
    },
    {
      name: 'Jane Smith',
      position: 'Instructora de Dibujo',
      bio: 'Jane Smith es una ilustradora talentosa conocida por sus retratos realistas y sus intrincados dibujos a lápiz. Le gusta enseñar a estudiantes de todas las edades y ayudar a que desarrollen sus habilidades de dibujo.',
      image: process.env.PUBLIC_URL + '/images/images.jpg',
    },
    // Add more instructors...
  ];
  
  
function Team() {
  return (
    <div className="team-container">
      <h1>Nuestro Equipo</h1>
      <div className="instructors">
        {instructors.map((instructor) => (
          <div key={instructor.name} className="instructor">
            <img src={instructor.image} alt={instructor.name} className="instructor-image" />
            <div className="instructor-details">
              <h2>{instructor.name}</h2>
              <p className="position">{instructor.position}</p>
              <p className="bio">{instructor.bio}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Team;
