import React from 'react';

function SchoolHome() {
  return (
    <div class="home-container">
      <div class="home-content">
        <h1>Página de inicio de la escuela</h1>
        <p>Gracias por visitar el sitio web de nuestra escuela.</p>
        <p>Explore nuestra amplia gama de clases y libere su creatividad.</p>
        <p>Descubra la belleza del arte a través de nuestras exposiciones en la galería.</p>
        <p>Únase a nuestra comunidad de personas que aprenden con pasión y deje que su imaginación vuele.</p>
      </div>
    </div>

  );
}

export default SchoolHome;
